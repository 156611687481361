<template>
  <router-link
    :to="projectPartner.links.detail"
    v-if="projectPartner"
    style="text-decoration: none"
  >
    <div class="card--listing">
      <div class="top--part">
        <card-listing-image
          :linkDetail="projectPartner.links.detail"
          :photos="[projectPartner.primary_photo.url]"
          :primaryPhoto="projectPartner.primary_photo"
          :allowMultiImage="allowMultiImage"
          :imageAlt="projectPartner.project_name"
        />
      </div>
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ projectPartner.project_name }}
        </h3>
        <div class="name multiline-ellipsis-2">
          {{ projectPartner.region_address }}
        </div>
        <div>
          <div class="price--after">
            {{ projectPartner.min_price }} - {{ projectPartner.max_price }}
          </div>
        </div>
        <div class="label--container">
          <div class="label mr-2">
            {{ $t('general.sell') }}
          </div>
          <div class="label">{{ projectPartner.property_type }}</div>
        </div>
      </div>
      <div class="project--partner-developer">
        <!-- ini bagian developer name -->
        <div class="developer--name">Developer: {{ projectPartner.developer_name }}</div>
        <img :src="projectPartner.developer_picture_url" />
      </div>
    </div>
  </router-link>
</template>

<script>
import CardListingImage from '@/components/utils/partials/card-listing-image';
import HelperMixin from '@/mixins/helpers';
export default {
  mixins: [HelperMixin],
  components: {
    CardListingImage,
  },
  props: {
    projectPartner: {
      default: null,
      require: true,
    },
    allowMultiImage: {
      default: false,
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
